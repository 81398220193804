<template>
  <v-container fluid>
     <v-row>
      <v-col cols="2">
       <v-subheader>受付日時</v-subheader>
      </v-col>
     <v-col cols="4">
      <v-dialog ref="dialog2" v-model="modal" :return-value.sync="date" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="受付日時" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-text-field>
        </template>
        <v-date-picker v-model="date" scrollable >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    </v-row>
 
 
     <v-row>
      <v-col cols="2">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="5">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      <v-col cols="5">
        <v-text-field label="カナ名称" value="やまだはなこ"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
   <v-row>
      <v-col cols="4">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="4">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="4">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="4">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
      <v-row>
      <v-col cols="4">
        <v-subheader>名前</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="漢字名称" value="山田花子" suffix="様"  clearable dense/>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
      <v-subheader>カナ</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field label="カナ名称" value="やまだはなこ" suffix="様" clearable dense/>
      </v-col>
    </v-row>
 
 
   </v-container>
</template>
 <script>
     export default {
    data: () => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
  
    }),
  }
</script>

<script>

import Vue from "vue";
import VueMask from 'v-mask'
Vue.use(VueMask);
import axiosJsonpAdapter from 'axios-jsonp'

export default {
  name: 'app',
  data() {
    return {
        show1: false,
        page: 1,
        zipcode: '',
        prefecture: '',
        address: '',
        error_msg: '',
        
        params: {
            name: '',
            datesk: '2021-05-10',
            inputDate: '2021-05-10',
            modal: '',
            datesk: '',
            password: '',
            name_kana: '',
            address: '',
            delivery_time: '',
            coupon_code: '',
            inzip: '1234567',
            intel: '0839765215',
            inmph: '08012341234',
            zipcd: '7540001',
            suuji: 123456789,
            insuu: 12345678,
            price: '1,000',
            password: 'Password',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            money: {
      decimal: '.',
      thousands: ',',
      prefix: '',
      suffix: ' ',
      precision: 0,
    }
        },
       } 
   },
   
   methods: {
    handleInput: function (event) {
      //代入前に何か処理を行う…
      this.message = event.target.value
    },
    fetchAddress() {
     // 郵便番号のバリデーションチェック
     // const reg = /^\d{7}$/
     // if (!reg.test(this.zipcode)) return
      const res =  this.axios.get('http://zipcloud.ibsnet.co.jp/api/search',{
        adapter: axiosJsonpAdapter,
        params: {
          zipcode: this.zipcode
        }
      }).then(res => {
          //正常時は 200
          if(res.data.status == 200){
            // 返却されたデータを挿入する
            this.prefecture = res.data.results[0].address1;
            this.address = res.data.results[0].address2 + res.data.results[0].address3;
        }else{
            this.error_msg =  "郵便番号エラー";
        }
        })
      },
        next() {
                this.page++;
            },
            prev() {
                this.page--;
            },
            submit() {
                /* ここでAjax送信 */
                // const url = '******';
                // axios.post(url, this.params)
                //     .then((response) => {
                //
                //         // ここで何かをする
                //
                //     });
            },
            navigationCss(targetPage) {
                if(this.page === targetPage) {  // 現在ページの場合
                    return ['col-4', 'text-center', 'text-white', 'bg-info', 'p-2'];
                }
                return ['col-4', 'text-center', 'text-secondary', 'p-2'];

            }
        }
    };

</script>
<style>
.form-control {
   text-decoration:underline;
  }
</style>